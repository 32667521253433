import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Tab {
  label: string;
  path: string;
  fullPath: string;  // Store full path including parameters and query params
  filterData: any;
}

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private storageKey = 'tabs';
  private tabsSubject: BehaviorSubject<Tab[]>;
  private activeTabSubject: BehaviorSubject<number>;

  
  tabs$:any;
  activeTab$:any;
  constructor() {
    const savedTabs = localStorage.getItem(this.storageKey);
    const initialTabs = savedTabs ? JSON.parse(savedTabs) : [];
    this.tabsSubject = new BehaviorSubject<Tab[]>(initialTabs);

    const savedActiveTab = localStorage.getItem(this.storageKey + '_active');
    const initialActiveTab = savedActiveTab ? Number(savedActiveTab) : 0;
    this.activeTabSubject = new BehaviorSubject<number>(initialActiveTab);
    this.tabs$ = this.tabsSubject.asObservable();
    this.activeTab$ = this.activeTabSubject.asObservable();
  }

  private saveTabs() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.tabsSubject.value));
  }

  private saveActiveTab() {
    localStorage.setItem(this.storageKey + '_active', this.activeTabSubject.value.toString());
  }

  get tabs(): Tab[] {
    return this.tabsSubject.value;
  }

  get activeTab(): number {
    return this.activeTabSubject.value;
  }

  addTab(tab: Tab) {
    const existingTab = this.tabs.findIndex(t => {
          if(t.path.includes('receiving-services/asn') && tab.path.includes('receiving-services/asn')) {
            // return t.path.startsWith('receiving-services/grn');
            t.path = tab.path
          }
          if(t.path.includes('receiving-services/putaway') && tab.path.includes('receiving-services/putaway')) {
            t.path = tab.path
          }
          if(t.path.includes('receiving-services/grn') && tab.path.includes('receiving-services/grn')) {
            // return t.path.startsWith('receiving-services/grn');
            t.path = tab.path
          }
          if(t.path.includes('order-services/order') && tab.path.includes('order-services/order')) {
            // return t.path.startsWith('receiving-services/grn');
            t.path = tab.path
          }
          return t.path === tab.path;
    });
    if (existingTab === -1) {
      this.tabsSubject.next([...this.tabs, tab]);
        this.setActiveTab(this.tabs.length);
    } else {
        this.setActiveTab(existingTab);
    }
    this.saveTabs();
    this.saveActiveTab();
  }

  setActiveTab(index: number) {
      this.activeTabSubject.next(index);
      this.saveActiveTab();
  }

  updateTab(index: number, tab: Tab) {
    const tabs = [...this.tabs];
    tabs[index] = tab;
    this.tabsSubject.next(tabs);
    this.saveTabs();
  }

  closeTab(index: number) {
    const tabs = [...this.tabs];
    tabs.splice(index, 1);
    this.tabsSubject.next(tabs);
    this.saveTabs();
    if (this.activeTab === index) {
      if (tabs.length > 0) {
        this.setActiveTab(index === 0 ? 0 : index - 1);
      } else {
        this.setActiveTab(0);
      }
    } else if (this.activeTab > index) {
      this.setActiveTab(this.activeTab - 1);
    }
    this.saveActiveTab();
  }
  closeAllTab(){
    const tabs: Tab[] = [];
    this.tabsSubject.next(tabs);
    this.saveTabs();
  }
  updateFilterData(index: number, filterData: any) {
    const tabs = [...this.tabs];
    tabs[index].filterData = filterData;
    this.tabsSubject.next(tabs);
    this.saveTabs();
  }

  transformFiltersArrayToObject(filtersArray: any[]): any {
    return filtersArray.reduce((acc, curr) => {
      if(curr.value){
        acc[curr.key] = curr.value;
      }
      if(curr.dateTimeValue){
        acc[curr.key] = curr.dateTimeValue;
      }
      return acc;
    }, {});
  }

}
