import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-change-color-modal',
  templateUrl: './change-color-modal.component.html',
  styleUrls: ['./change-color-modal.component.css'],
  providers: [MessageService]
})
export class ChangeColorModalComponent implements OnInit {
  selectedThemeColor = '#000000';
  selectedTextColor = '#000000';
  selectedBgColor = '#f6f4f4';
  selectedSidebarBgColor = '#fff'
  selectedMainTextColor = '#313131'
  bgType = 'gradiant'
  sidebarType = 'left'
  darkTheme: any;
  constructor(public ref: DynamicDialogRef,private messageService: MessageService) { 
  }

  ngOnInit() {
    const primaryColor = localStorage.getItem('backgroundPrimaryColor')
    this.selectedThemeColor = primaryColor! 
    const mainBgColor = localStorage.getItem('backgroundMainColor')
    this.selectedBgColor = mainBgColor! 
    const sidebarBgColor = localStorage.getItem('backgroundSidebarColor')
    this.selectedSidebarBgColor = sidebarBgColor!
    const mainTextColor = localStorage.getItem('mainTextColor')
    this.selectedMainTextColor = mainTextColor! 
    const bgType = localStorage.getItem('bgType')
    this.bgType = bgType! 
    const sidebarType = localStorage.getItem('sidebarType')
    this.sidebarType = sidebarType!
  }
  submit(){
    localStorage.setItem('backgroundPrimaryColor', this.selectedThemeColor);
    localStorage.setItem('backgroundPrimaryHoverColor', (this.selectedThemeColor + '14'));
    document.documentElement.style.setProperty('--background-primary-color', this.selectedThemeColor);
    document.documentElement.style.setProperty('--background-primary-hover-color', (this.selectedThemeColor + '14'));
    

    localStorage.setItem('backgroundSidebarColor', this.selectedSidebarBgColor);
    document.documentElement.style.setProperty('--sidebar-background-color', this.selectedSidebarBgColor);

    localStorage.setItem('mainTextColor', this.selectedMainTextColor);
    document.documentElement.style.setProperty('--main-text-color', this.selectedMainTextColor);
    
    localStorage.setItem('bgType', this.bgType);
    const sidebarType = localStorage.getItem('sidebarType')
    
    localStorage.setItem('backgroundMainColor', this.selectedBgColor);
    if(this.bgType === 'gradiant'){
      document.body.style.backgroundColor = ''
      document.body.style.setProperty('--main-background-color', this.selectedBgColor);
    }
    else{
      document.body.style.setProperty('--main-background-color', this.selectedBgColor);
      this.darkTheme = localStorage.getItem('darkTheme')
      if(this.darkTheme === 'true'){
        document.body.style.backgroundColor = ''
      }
      else{
        document.body.style.backgroundColor = this.selectedBgColor
      }
    }
    if(sidebarType !== this.sidebarType){
      // this.messageService.add({ severity: 'info', summary: 'Info', life: 5000, detail: 'Your have changes the sidebar view for changing view reloading the page' });
      localStorage.setItem('sidebarType', this.sidebarType);
      setTimeout(() => {
        location.reload();
        this.ref.close(true)
      }, 1000);
    }
    else{
      this.ref.close(true)
    }
  }
  reset(){
    document.documentElement.style.setProperty('--background-primary-color', '#973CB6');
    document.documentElement.style.setProperty('--background-primary-hover-color', '#973CB614');
    document.body.style.setProperty('--main-background-color', '#f6f4f4');
    document.documentElement.style.setProperty('--sidebar-background-color', '#ffffff');
    document.documentElement.style.setProperty('--main-text-color', '#313131');
    this.bgType = 'gradiant'
    this.sidebarType = 'left'
    this.selectedThemeColor = '#973CB6'
    this.selectedBgColor = '#f6f4f4'
    this.selectedSidebarBgColor = '#ffffff'
    this.selectedMainTextColor = '#313131'
    localStorage.setItem('backgroundPrimaryColor', this.selectedThemeColor);
    localStorage.setItem('backgroundPrimaryHoverColor', (this.selectedThemeColor + '14'));
    localStorage.setItem('backgroundMainColor', this.selectedBgColor);
    localStorage.setItem('backgroundSidebarColor', this.selectedSidebarBgColor);
    localStorage.setItem('mainTextColor', this.selectedMainTextColor);
    localStorage.setItem('bgType', this.bgType);
    localStorage.setItem('sidebarType', this.sidebarType);
  }
}
