export class Configuration {
  public CONFIG_URL = {
    USER_LOGIN: 'auth-service/auth/login',
    SIGNUP: 'auth-service/auth/login',
    ROLE_MAPING:'auth-service/role/getRoleMapping',
    GET_ORDER: 'oms-service/order/getOrders',
    GET_ALL_STATUS: 'oms-service/master/getOrderStatusMaster',
    CREATE_ORDER:'oms-service/order/createOrder',
    UPDATE_ORDER: 'oms-service/order/updateOrder',
    GET_ORDER_BY_ID:'oms-service/order/getOrder?orderNumber=',
    GET_CLIENTS_BY_ID:'client-service/info/getClientById?clientId=',
    GET_MULTI_CLIENTS_BY_ID:'client-service/info/getMultiClientById',
    GET_MULTI_WAREHOUSE_BY_ID:'warehouse-service/info/getMultiWarehouseById',
    GET_TRACKING_STATUS_BY_ID:'oms-service/order/createLabel?orderNumber=',
    MERGE_LABELS:'/carrier-service/labels/mergeLabels',
    PENDING_MERGE_LABELS:'oms-service/order/createMultiLabel?orderNumber=',
    GET_TRACKING_ORDER:'oms-service/order/getOrderTrackingStatus?orderNumber=',
    GET_RETURN_REASON:'oms-service/master/getReturnsReasonMaster',
    BULK_ORDER_UPLOAD:'utils-service/uploads/uploadOrders?Id=1',
    CREATE_ORDER_RETURN:'oms-service/order/createReturnOrder',
    ORDER_SUMMERY:'oms-service/dashboard/orderSummary',
    CURRENT_ORDER_SUMMERY:'oms-service/dashboard/currentOrderDetails',
    USER_LIST:'auth-service/auth/getUsers',
    CREATE_USER:'auth-service/auth/createUser',
    UPDATE_USER:'auth-service/auth/updateUser',
    CHECK_USER:'auth-service/auth/checkUserName?userName='
  };
}