// token.interceptor.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { Observable, finalize, tap } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { MessageService } from 'primeng/api';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  authRequest:any;
  private requests: HttpRequest<any>[] = [];
  constructor(private auth : AuthService,private messageService: MessageService, private sharedService: SharedService){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.sharedService.updateLoader(true);
    const authToken = this.auth.getAuthToken() ? this.auth.getAuthToken() : localStorage.getItem('access_token');
    const apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];

    const requestToHandle = authToken
      ? request.clone({
          headers: request.headers.set('Authorization', `Bearer ${authToken}`),
          setHeaders: { 'DEVICE-TYPE': 'Web', VER: '1' },
        })
      : request;

    return next.handle(requestToHandle).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status && (event.status === 200 || event.status === 201)) {
              // Your logic here for successful responses
            }
          }
        },
        (error: any) => {
          this.sharedService.updateLoader(false);
          if (error.status === 401) {
            this.auth.logOut();
          } else if (apidetector == 'checkUserName') {
            // Your specific logic for 'checkUserName'
          } else if (error.status === 404) {
            // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.responseMessage : 'Something went wrong' });
          } else {
            console.log('Error +++ ', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.responseMessage : 'Something went wrong' });
          }
        }
      ),
      finalize(() => {
        this.sharedService.updateLoader(false);
      })
    );
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   this.sharedService.updateLoader(true);
  //   // Get the authentication token from wherever you store it (e.g., localStorage, a service, etc.)
  //   // console.log("localStorage authToken",localStorage.getItem('access_token'))
  //   const authToken = this.auth.getAuthToken() ? this.auth.getAuthToken() : localStorage.getItem('access_token') 
  //   var apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];
  //   // console.log(apidetector)
  //   const requestToHandle = authToken
  //           ? request.clone({
  //               headers: request.headers.set('Authorization', `Bearer ${authToken}`),
  //               setHeaders: { 'DEVICE-TYPE': 'Web',VER:'1' },
  //           })
  //           : request;
  //           return next.handle(requestToHandle).pipe(tap((event: HttpEvent<any>) => {
  //             if (event instanceof HttpResponse) {
  //                 if (event.status && (event.status === 200 || 201)) {
                    
  //                 }
  //             }
  //         }
  //         , (error:any) => {
  //           this.sharedService.updateLoader(false);
  //             if (error.status === 401) {
  //               this.auth.logOut()
  //             } else if(apidetector == 'checkUserName'){
  //               // console.log(apidetector)
  //               // return error;
  //             }
  //             else if(error.status === 404){
  //               this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.responseMessage : 'Something went wrong' });
  //             }
  //             else{
  //               console.log("Error +++ ",error)
  //               this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.responseMessage : 'Something went wrong' });
  //               // this.ErrorService.errormsg(error ? error.error.responseMessage : 'Something went wrong');

  //             }
  //         }));
  // }
  
}

export let tokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor, 
  multi: true,
};