import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';
import { RouterService } from './shared/services/router.service';
import { Subscription } from 'rxjs';
import { SharedService } from './shared/shared.service';
import { WebsocketService } from './shared/services/websocket.service';
import { AuthService } from './shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  // title = 'wms';
  showLoader = false;
  showLoader1 = false;
  loaderSubscription!: Subscription;
  loaderSubscription1!: Subscription;
  websocketSubscription!: Subscription;
  messages: string[] = [];
  serverUrl: string = '';
  authToken:any;
  constructor(private _route: Router,private routerService: RouterService,private websocketService: WebsocketService, private auth: AuthService,
    private sharedService: SharedService,){
      this.serverUrl = `${environment.WebsocketUrl}`
    this.authToken = this.auth.getAuthToken() ? this.auth.getAuthToken() : localStorage.getItem('access_token');
    this.websocketSubscription = this.websocketService.connect(this.serverUrl,this.authToken).subscribe(
      (message: MessageEvent) => {
        this.messages.push(message.data);
        console.log(message.data,this._route.url)
      },
      (error) => console.error(error),
      () => console.log('WebSocket connection closed')
      );
    const user_details = localStorage.getItem('user_details');
    if(user_details){
      localStorage.setItem('isloggedin','true')
    }else{
      localStorage.setItem('isloggedin','false')
    }
    // const isloggedIn =  localStorage.getItem('isloggedin')
    // console.log("isloggedIn ppppp",isloggedIn)
    // if(isloggedIn === 'true'){
      // this._route.navigate(['/order']);
    // }else{
      // this._route.navigate([''])
    // }
  }
  ngOnInit(): void {
    this.loaderSubscription = this.sharedService.loaderState$.subscribe((state: any) => {
      this.showLoader = state;
    });
    this.loaderSubscription1 = this.sharedService.loaderState1$.subscribe((state: any) => {
      this.showLoader1 = state;
    });
    this.routerService.ActivateRouteService();
  }  

  ngOnDestroy(): void {
    if (this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
    }
    this.websocketService.close();
  }

  sendMessage(message: string): void {
    this.websocketService.send(message);
  }

  ngAfterViewInit() {
    feather.replace();
  }
}
