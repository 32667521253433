import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private darkTheme = false;

  constructor() {
    // Load the initial theme state from local storage if available
    const savedTheme = localStorage.getItem('darkTheme');
    this.darkTheme = savedTheme === 'true';
    this.applyTheme();
  }

  toggleTheme() {
    const savedTheme = localStorage.getItem('darkTheme');
    this.darkTheme = savedTheme === 'true';
    this.darkTheme = !this.darkTheme;
    localStorage.setItem('darkTheme', this.darkTheme.toString());
    this.applyTheme();
  }

  isDarkTheme(): boolean {
    return this.darkTheme;
  }

  private applyTheme() {
    if (this.darkTheme) {
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
    } else {
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
    }
  }
}
