import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SharedService {
    loaderState = new Subject();
    loaderState$ = this.loaderState.asObservable();
    
    loaderState1 = new Subject();
    loaderState1$ = this.loaderState1.asObservable();

    constructor() { }

    /**
     * To update loader across the app
     * @param state Show/Hide
     */
    updateLoader(state:any) {
        this.loaderState.next(state);
    }
    updateLoader1(state: any) {
        this.loaderState1.next(state);
    }

    // accept only numbers and float only 
    isNumberKey(evt:any) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        var value = evt.target.value;
        var dotcontains = value.indexOf(".") != -1;
        if (dotcontains)
            if (charCode == 46) return false;
        if (charCode == 46) return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    
getPredefinedDateRange(option: string): { startDate: any, endDate: any } | null {
    const today = new Date();
    let startDate: Date;
    let endDate: Date;
  
    switch (option) {
      case 'today':
        startDate = new Date(today);
        startDate.setHours(0, 0, 0, 0); 
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999); 
        break;
  
      case 'yesterday':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        startDate.setHours(0, 0, 0, 0); 
        endDate = new Date(today);
        endDate.setDate(today.getDate() - 1);
        endDate.setHours(23, 59, 59, 999); 
        break;
  
      case 'last-week':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        startDate.setHours(0, 0, 0, 0); 
        endDate = new Date(today);
        endDate.setDate(today.getDate() - 1);
        endDate.setHours(23, 59, 59, 999); 
        break;
  
      case 'last-month':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999); 
        break;

    case 'current-month':
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate.setHours(0, 0, 0, 0); // Start of this month
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999); // End of this month
        break;
  
      default:
        return null;
    }
  
    return { startDate, endDate };
  }
  
  convertToDateFormat(dateString: string): any {
    // Parse the date string using a custom format
    const [day, month, year, time, timezone] = dateString.split(/[-\s:]+/);
  
    // Construct a new date object from the parsed components
    const parsedDate = new Date(
      `${year}-${month}-${day}T${time}:${timezone}`
    );
  
    // If the date is invalid, handle the error
    if (isNaN(parsedDate.getTime())) {
      return 'Invalid Date';
    }
    else{
      return parsedDate
    }
  }
  
  convertToISOFormat(dateString: string): string {
    // Split the input date string into components
    const [day, month, year, hours, minutes, seconds, timezone] = dateString.match(/\d+/g)!;
  
    // Create a new Date object using the extracted values
    const date = new Date(
      Number(year),
      Number(month) - 1,  // Month is 0-indexed in JavaScript Date object
      Number(day),
      Number(hours),
      Number(minutes),
      Number(seconds)
    );
  
    // Get timezone offset in minutes and adjust the time accordingly
    const timezoneOffset = Number(timezone.slice(0, 3)) * 60 + Number(timezone.slice(3, 5));
    date.setMinutes(date.getMinutes() - timezoneOffset);
  
    // Convert the date to the required ISO format with hours and minutes
    const isoString = date.toISOString().slice(0, 16);
  
    return isoString;
  }
  
}
