import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as feather from 'feather-icons';
import { roleService } from '../../services/rolebase-services';
import { MenuItem, MessageService } from 'primeng/api';
import { ThemeService } from '../../services/theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabService } from '../../tab.service';
import { DOCUMENT } from '@angular/common';
import { ChangeColorModalComponent } from '../change-color-modal/change-color-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { svgs } from 'src/app/icons';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  providers: [MessageService,DialogService]
})
export class SidebarComponent implements OnInit{
  ref: DynamicDialogRef | undefined;
  isMobile: boolean = false;
  isModuleActive:boolean=false;
  isSubModuleActive:boolean= false;
  items: MenuItem[] = [];
  themeValue = false;
  theme = 'dark'
  sidebarType = 'left'
  showHideSettingsModule = false
  svgIcon: any;
  svgIcons = svgs
  menu = [
    {
      moduleName:"Products",
      items: [
        {
            "subModuleName": "Manage Products",
            'url':"/product-services/product-list",
            'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Products),
            'activeIcon': 'assets/img/sidebarIcon/manageProducticon - Copy.svg'
        },
        {
          "subModuleName": "Manage Attributes",
          'url':"/product-services/product-attribute",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Attributes),
          'activeIcon': 'assets/img/sidebarIcon/manageProductAttributeIcon - Copy.svg'
        }
     ]
    },
    {
      moduleName:"Inbound",
      items: [
        
        {
          "subModuleName": "Manage PO",
          'url':"/receiving-services/manage-po",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_PO),
          'activeIcon': 'assets/img/sidebarIcon/managePo - Copy.svg'
        },
        {
          "subModuleName": "Manage ASN",
          'url':"/receiving-services/asn-manage",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_ASN),
          'activeIcon': 'assets/img/sidebarIcon/manageAsn copy.svg'
        },
        {
            "subModuleName": "GRN Details",
            'url':"/receiving-services/grn-list",
            'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.GRN_Details),
            'activeIcon': 'assets/img/sidebarIcon/grnIcon - Copy.svg'
        },
        {
          "subModuleName": "Putaway",
          'url':"/receiving-services/putaway-list",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Putaway),
          'activeIcon': 'assets/img/sidebarIcon/inventoryLocation - Copy.svg'
      }
     ]
    },
    {
      moduleName:"Outbound",
      items: [
        {
            "subModuleName": "Order Management",
            'url':"/order-services/order",
            'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Order_Management),
            'activeIcon': 'assets/img/sidebarIcon/grnIcon - Copy.svg'
        },
        {
          "subModuleName": "Picklist Management",
          'url':"/order-services/picklist",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Picklist),
          'activeIcon': 'assets/img/sidebarIcon/grnIcon - Copy.svg'
      },

        
      //   {
      //     "subModuleName": "Create Order ",
      //     'url':"/order-services/create-order",
      //     'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Layout),
      //     'activeIcon': 'assets/img/sidebarIcon/grnIcon - Copy.svg'
      // },
      //   {
      //     "subModuleName": "Upload Order",
      //     'url':"/order-services/import-order",
      //     'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Layout),
      //     'activeIcon': 'assets/img/sidebarIcon/uploadIcon - Copy.svg'
      //   },
     ]
    },
    {
      moduleName:"Inventory",
      items: [
        {
          "subModuleName": "Manage Inventory",
          'url':"/inventory-services/manage-inventory",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Inventory),
          'activeIcon': 'assets/img/sidebarIcon/inventoryLocation - Copy.svg'
      },
        {
            "subModuleName": "Inventory summary",
            'url':"/inventory-services/inventory-summary",
            'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Inventory_summary),
            'activeIcon': 'assets/img/sidebarIcon/inventorySummary - Copy.svg'
        },
      {
        "subModuleName": "Inventory Upload",
        'url':"/pageNotFound",
        'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Inventory_Upload),
        'activeIcon': 'assets/img/sidebarIcon/uploadIcon - Copy.svg'
    },
     ]
    },
    {
      moduleName:"Warehouse",
      items: [
        {
            "subModuleName": "Manage Warehouse",
            'url':"/warehouse-services/manage-locations",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Warehouse),
          'activeIcon': 'assets/img/sidebarIcon/manageWarehouse (2) - Copy.svg'
        },
        {
          "subModuleName": "Manage Layout",
          'url':"/warehouse-services/warehouse-plan",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Layout),
          'activeIcon': 'assets/img/sidebarIcon/warehouseLayout - Copy.svg'
      },
        {
          "subModuleName": "Manage Containers",
          'url':"/warehouse-services/container",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Containers),
          'activeIcon': 'assets/img/sidebarIcon/warehouseBin - Copy.svg'
        },
        {
          "subModuleName": "Manage Bin",
          'url':"/warehouse-services/manage-bin",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Bin),
          'activeIcon': 'assets/img/sidebarIcon/warehouseBin - Copy.svg'
        },
        {
          "subModuleName": "Manage Vendors",
          'url':"/warehouse-services/manage-vendors",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Vendors),
          'activeIcon': 'assets/img/sidebarIcon/manageSupplier - Copy.svg'
        }
     ]
    },

    {
      moduleName:"Account Configurations",
      items: [
        {
            "subModuleName": "Manage Account",
            'url':"/pageNotFound",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Account),
          'activeIcon': 'assets/img/sidebarIcon/clientAccount - Copy.svg'
        },
        {
          "subModuleName": "Manage Webhooks",
          'url':"/pageNotFound",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Manage_Webhooks),
          'activeIcon': 'assets/img/sidebarIcon/manageWebhook - Copy.svg'
      },
     ]
    },

    {
      moduleName:"Access Management",
      items: [
        {
            "subModuleName": "User Management",
            'url':"/access-management-services/user-management",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.User_Management),
          'activeIcon': 'assets/img/sidebarIcon/userManage - Copy.svg'
        },
        {
          "subModuleName": "Role Management",
          'url':"/access-management-services/role-management",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Role_Management),
          'activeIcon': 'assets/img/sidebarIcon/roleManage - Copy.svg'
      },
     ]
    },
    {
      moduleName:"Utility",
      items: [
        {
          "subModuleName": "Common Upload",
          'url':"/utility/upload",
          'icon': this.sanitizer.bypassSecurityTrustHtml(svgs.Common_Upload),
          'activeIcon': 'assets/img/sidebarIcon/uploadIcon - Copy.svg'
        },
       
     ]
    },
  ]
  constructor(private auth: AuthService, public roleService: roleService,public themeService: ThemeService,public router: Router,
    @Inject(DOCUMENT) private document: Document,private route: ActivatedRoute,private sanitizer: DomSanitizer,
    private renderer: Renderer2,public dialogService: DialogService,private tabService: TabService) {
    this.sidebarType = localStorage.getItem('sidebarType')!
    document.body.classList.remove('top-sidebar')
    this.checkIfMobile();
  }
  
  ngAfterViewInit() {
    feather.replace();
  }

  ngOnInit() {
    const isExpanded = localStorage.getItem('expandedSidebar')
    if(isExpanded === 'true'){
      this.renderer.removeClass(this.document.body, 'sidebar-collapse');
    }
    else{
      this.renderer.addClass(this.document.body, 'mini-sidebar')
    }

    const theme = localStorage.getItem('darkTheme')
    this.themeValue = theme === 'true' ? true : false
    // this.roleService.fetchRoleList();
    const bgType = localStorage.getItem('bgType');
    const primaryColor = localStorage.getItem('backgroundPrimaryColor')
    document.documentElement.style.setProperty('--background-primary-color', primaryColor);
    document.documentElement.style.setProperty('--background-primary-hover-color', (primaryColor + '14'));
    const mainBgColor = localStorage.getItem('backgroundMainColor')
    if(bgType === 'gradiant'){
      document.body.style.backgroundColor = ''
      document.body.style.setProperty('--main-background-color', mainBgColor);
    }
    else{
      document.body.style.setProperty('--main-background-color', mainBgColor);
      if(this.themeValue){
        document.body.style.backgroundColor = ''
      }
      else{
        document.body.style.backgroundColor = mainBgColor!
      }
    }
    const sidebarBgColor = localStorage.getItem('backgroundSidebarColor')
    document.documentElement.style.setProperty('--sidebar-background-color', sidebarBgColor);
    const mainTextColor = localStorage.getItem('mainTextColor')
    document.documentElement.style.setProperty('--main-text-color', mainTextColor);
    this.items = [
      {
        label: 'Manage Product',
        icon: 'pi pi-fw pi-user',
        routerLink:"/product-services/product-list"
      },
      {
        label: 'Manage Product Attributes',
        icon: 'pi pi-fw pi-user',
        routerLink:"/product-services/product-attribute"
      },
      {
        label: 'Upload Products',
        icon: 'pi pi-fw pi-user',
        routerLink:"/product-services/upload-product"
      },
    ];
    // this.getWarehouseList()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile(); // Check whenever the window is resized
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 769; 
  }

  toggleNavMobile() {
    if (this.isMobile) {
      const toggleBtn: any = document.getElementById('mobile_btn');

      const body = document.body;
      // const wrapper = document.querySelector(".main-wrapper");

      if (document.querySelector(".slide-nav")) {
        document.querySelector(".main-wrapper")?.classList.remove("slide-nav");

        body.classList.remove('menu-opened');
        // toggleBtn.classList.add('active');
        // localStorage.setItem(screenModeNightTokenState, 'night');
        setTimeout(() => {
          body.classList.remove('mini-sidebar');
          document.querySelector(".header-left")?.classList.add("active");
        }, 100);
      } else {
        document.querySelector(".main-wrapper")?.classList.add("slide-nav");

        body.classList.add('menu-opened');
        toggleBtn.classList.remove('active');
        // localStorage.removeItem(screenModeNightTokenState);
        setTimeout(() => {
          body.classList.add('mini-sidebar');
          document.querySelector(".header-left")?.classList.remove("active");
        }, 100);
      }
    }
  }
  logOut() {
    this.auth.logOut()
  }
  toggleTheme() {
    this.themeService.toggleTheme();
  }

  // getWarehouseList() {
  //   // const userDetails = this.getUserDetails
  //   // this.getUserDetails = JSON.parse(userDetails)
  //   const id = 0
  //   // const extractedIds = this.getUserDetails?.userWarehouseMapping.map((item: any) => item.warehouseId);
  //   this.auth.getWarehouseId(id).subscribe((res: any) => {
  //     const wareHouseList = res?.responseObject
  //     localStorage.setItem('wareHouseList', JSON.stringify(wareHouseList))
  //   })
  // }

  showPoDetails(){
    this.ref = this.dialogService.open(ChangeColorModalComponent, {
      // header: 'Select a Product',
      width: '35vw',
      data: {},
      contentStyle: { overflow: 'auto' },
      // breakpoints: {
      //   '960px': '75vw',
      //   '640px': '90vw',
      // },
      // templates: {
      //   footer: Footer,
      // },
    });
  
    this.ref.onClose.subscribe((data: any) => {
      let summary_and_detail;
      if (data) {
        const buttonType = data?.buttonType;
        summary_and_detail = buttonType
          ? {
              summary: 'No Product Selected',
              detail: `Pressed '${buttonType}' button`,
            }
          : { summary: 'Product Selected', detail: data?.name };
      } else {
        summary_and_detail = {
          summary: 'No Product Selected',
          detail: 'Pressed Close button',
        };
      }
      // this.messageService.add({
      //   severity: 'info',
      //   ...summary_and_detail,
      //   life: 3000,
      // });
    });
  }

  addNewTab(item:any) {
    this.tabService.addTab({
      label: item.subModuleName, path: item.url,
      fullPath: '',
      filterData: []
    });
  }
  showSettingsModule(){
    this.showHideSettingsModule = !this.showHideSettingsModule
  }
}
