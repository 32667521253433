import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  socket!: WebSocket;
  private subject: Subject<MessageEvent>;

  constructor() {
    this.subject = new Subject<MessageEvent>();
  }

  connect(url: string,token:any): Observable<MessageEvent> {
    this.socket = new WebSocket(url,['protocol-one']);
    const completeUrl = `${url}?token=${token}`;

    this.socket = new WebSocket(completeUrl);
    console.log('Websocket connected')
    this.socket.onmessage = (event) => this.subject.next(event);
    this.socket.onerror = (event) => this.subject.error(event);
    this.socket.onclose = (event) => this.subject.complete();

    return this.subject.asObservable();
  }

  send(message: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    }
  }

  close(): void {
    if (this.socket) {
      this.socket.close();
    }
  }
}
