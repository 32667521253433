export class WarehouseConfiguration {
  public CONFIG_URL = {
    USER_LOGIN: 'auth-service/auth/login',
    SIGNUP: 'auth-service/auth/login',
    ROLE_MAPING:'auth-service/role/getRoleMapping',
    GET_ORDER: 'order-service/order/getOrders',
    GET_ALL_STATUS: 'order-service/master/getOrderStatusMaster',
    CREATE_ORDER:'order-service/order/createOrder',
    UPDATE_ORDER: 'order-service/order/updateOrder',
    GET_ORDER_BY_ID:'order-service/order/getOrder?orderNumber=',
    GET_CLIENTS_BY_ID:'client-service/info/getClientById?clientId=',
    GET_MULTI_CLIENTS_BY_ID:'client-service/info/getMultiClientById',
    GET_MULTI_WAREHOUSE_BY_ID:'warehouse-service/info/getMultiWarehouseById?warehouseList=',
    GET_TRACKING_STATUS_BY_ID:'order-service/order/createLabel?orderNumber=',
    MERGE_LABELS:'/carrier-service/labels/mergeLabels',
    PENDING_MERGE_LABELS:'order-service/order/createMultiLabel?orderNumber=',
    GET_TRACKING_ORDER:'order-service/order/getOrderTrackingStatus?orderNumber=',
    GET_RETURN_REASON:'order-service/master/getReturnsReasonMaster',
    BULK_ORDER_UPLOAD:'utils-service/uploads/uploadOrders?Id=1',
    CREATE_ORDER_RETURN:'order-service/order/createReturnOrder',
    ORDER_SUMMERY:'order-service/dashboard/orderSummary',
    CURRENT_ORDER_SUMMERY:'order-service/dashboard/currentOrderDetails',
    USER_LIST:'auth-service/auth/getUsers',
    CREATE_USER:'auth-service/auth/createUser',
    UPDATE_USER:'auth-service/auth/updateUser',
    CHECK_USER:'auth-service/auth/checkUserName?userName=',

    // Warehouse-service
    GET_WAREHOUSE_BY_FILTER: 'warehouse-service/info/getWarehouseByFilter',
    CREATE_WAREHOUSE_LOCATION: 'warehouse-service/config/createWarehouseLocation',
    UPDATE_WAREHOUSE_LOCATION: 'warehouse-service/config/updateWarehouse',
    GET_CONTAINER_BY_FILTER: 'warehouse-service/container/getContainerByFilter',
    GET_BIN_DETAILS: 'warehouse-service/locations/getBinDetails',
    ADD_CONTAINER: 'warehouse-service/container/createContainer',
    UPDATE_CONTAINER: 'warehouse-service/container/updateContainer',
    GET_WAREHOUSE_LAYOUT: 'warehouse-service/info/getWarehouseLayout',
    ADD_WAREHOUSE_LAYOUT: 'warehouse-service/config/createWarehouseLayout',

    // Master-API
    GET_CONTAINER_STATUS: 'warehouse-service/master/getContainerStatus',
    GET_CONTAINER_TYPE: 'warehouse-service/master/getContainerTypes'
  };
}