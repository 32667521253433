import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
  let _route = inject(Router)
  let isloggedin = localStorage.getItem("isloggedin")
  console.log("loggedIn",isloggedin)
  if(isloggedin == 'true'){
    return true
  }else{
    _route.navigate(['login']);
    return false;
  }
};
