<div class="main-wrapper">
<ng-container>
    <app-header></app-header>  
    <app-top-sidebar *ngIf="sidebarType === 'top'"></app-top-sidebar>
</ng-container>
  
  <ng-container >
    <app-sidebar *ngIf="sidebarType === 'left' || sidebarType === 'leftCollapsed'"></app-sidebar>

    
  </ng-container>
  <div class="router-outlets">
    <div *ngFor="let tab of tabs$ | async; let i = index" [class.active]="(activeTab$ | async) === i">
      <router-outlet *ngIf="(activeTab$ | async) === i" [name]="'outlet' + i"></router-outlet>
    </div>
  </div>
  <router-outlet></router-outlet> 
</div>