import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HeaderComponent } from './component/header/header.component';
import { CustomDatePipe } from '../_pipe/datepipe.pipe';
import { AppMaterialModule } from '../_material/material.module';
import { CustomDateRangeComponent } from './component/custom-date-range/custom-date-range.component';
import { dateRangeComponent } from './component/date-range/date-range.component';
import { searchDropDownFilter } from '../_pipe/search-filter.pipe';
import { PanelMenuModule } from 'primeng/panelmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MessageService } from 'primeng/api';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastModule } from 'primeng/toast';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { MegaMenuModule } from 'primeng/megamenu';
import { ChangeColorModalComponent } from './component/change-color-modal/change-color-modal.component';
@NgModule({
  declarations: [
    CustomDatePipe,
    CustomDateRangeComponent,
    dateRangeComponent,
    searchDropDownFilter,
    ChangeColorModalComponent,
  ],
  exports: [
    CustomDatePipe,
    CustomDateRangeComponent,
    dateRangeComponent,
    searchDropDownFilter,
    OverlayPanelModule,
    TieredMenuModule,
    NgSelectModule,
    ToastModule,
    ChangeColorModalComponent,
    FormsModule,
    ColorPickerModule,
    MegaMenuModule,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    PanelMenuModule,
    OverlayPanelModule,
    TieredMenuModule,
    NgSelectModule,
    ToastModule,
    FormsModule,
    ColorPickerModule,
    MegaMenuModule,
  ],
  providers: [DialogService],
})

// @NgModule({
//   declarations: [
//     HeaderComponent,
//     SidebarComponent
//   ],
//   imports: [
//     CommonModule
//   ],
//   exports:[
//     HeaderComponent,
//     SidebarComponent
//   ]
// })
export class SharedModule {}
