import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TabService } from '../shared/tab.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit  {
  sidebarType = 'top';
  tabs$ = this.tabService.tabs$;
  activeTab$ = this.tabService.activeTab$;
  constructor(public router: Router,private tabService: TabService){
    this.sidebarType = localStorage.getItem('sidebarType')!
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const fullPath = event.urlAfterRedirects;
        const path = event.urlAfterRedirects.split('?')[0];
        const existingTabIndex = this.tabService.tabs.findIndex(tab => tab.path === path);
        if (existingTabIndex !== -1) {
          this.tabService.updateTab(existingTabIndex, { ...this.tabService.tabs[existingTabIndex], fullPath });
          this.tabService.setActiveTab(existingTabIndex);
        }
      }
    });

    // On initial load, navigate to the active tab's route
    const tabs = this.tabService.tabs;
    if (tabs.length > 0) {
      const activeTabIndex = this.tabService.activeTab;
      this.router.navigateByUrl(tabs[activeTabIndex].fullPath);
    }
  }
}
