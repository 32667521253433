<div class="sidebar" id="sidebar " [ngClass]="[theme === 'dark'? 'dark' : '', sidebarType === 'leftCollapsed' ? 'leftCollapsed' : '']">
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul>
                 <li class="submenu-open" *ngFor="let item of menu" routerLinkActive="activeMenu" (click)="showHideSettingsModule = false"> 
                    <h6  class="submenu-hdr" [routerLink]="item.items[0].url">
                        <span>
                            {{item.moduleName}} 
                        </span>
                        <span *ngIf="sidebarType === 'leftCollapsed'" class="arrows" data-feather="chevron-down"></span>
                    </h6>
                    <ul routerLinkActive = 'openNav'>
                        <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngFor="let submenu of item.subModules"> -->
                            <li *ngFor="let submenu of item.items" (click)="addNewTab(submenu)">
                            <a [routerLink]="[submenu.url]" routerLinkActive="active" id="mobile_btn" (click)="toggleNavMobile()">
                                <span [ngClass]="{'active-icon': submenu.url === router.url}" [innerHTML]="submenu.icon"></span>
                            <!-- <img class="mr-10" [src]="submenu.url === router.url ? submenu.activeIcon : submenu.icon "  alt=""> -->
                            <span>{{submenu.subModuleName}}</span></a>
                        </li>
                    </ul>
                </li>
                <li class="submenu-open">
                    <h6 class="submenu-hdr" (click)="showSettingsModule()" [ngClass]="{'activeMenu': showHideSettingsModule}">
                        <span>
                            Settings
                        </span>
                        <span *ngIf="sidebarType === 'leftCollapsed'" class="arrows" data-feather="chevron-down"></span>
                    </h6>
                    <ul [ngClass]="{'openNav': showHideSettingsModule}">
                        <li>
                            <a (click)="logOut()" title="Logout"><i data-feather="log-out"></i><span>Logout</span> </a>
                        </li>
                        <li>
                            <a (click)="showPoDetails()" title="Change Theme"><i data-feather="layout"></i><span>Change Theme</span> </a>
                        </li>
                        <li>
                            <a>
                                <div class="mobilview">
                                    <input type="checkbox" class="checkbox" id="checkbox1" [(ngModel)]="themeValue" (click)="toggleTheme()">
                                    <label for="checkbox1" class="checkbox-label">
                                      <i data-feather="moon" class="fas fa-moon"></i>
                                      <i data-feather="sun" class="fas fa-sun"></i>
                                      <span class="ball"></span>
                                    </label>
                                  </div>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>