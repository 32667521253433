<p-toast />
<div class="po-details">
    <div class="modal-header">
    <h4 class="modal-title">
        Change Theme
    </h4>
    </div>
    <div class="modal-body">
        <div class="card border-none">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Select Primary Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedThemeColor" [(colorPicker)]="selectedThemeColor"/>
                                <div [style.background]="selectedThemeColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Select Text Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedMainTextColor" [(colorPicker)]="selectedMainTextColor"/>
                                <div [style.background]="selectedMainTextColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Main Background Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedBgColor" [(colorPicker)]="selectedBgColor"/>
                                <div [style.background]="selectedBgColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Background Type</label>
                            <div class="d-flex">
                                <select name="backgroundType" class="form-control" [(ngModel)]="bgType">
                                    <option value="gradiant">Gradiant</option>
                                    <option value="solid">Solid</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Sidebar/Header Background Color</label>
                            <div class="d-flex position-relative">
                                <input class="form-control" [(ngModel)]="selectedSidebarBgColor" [(colorPicker)]="selectedSidebarBgColor"/>
                                <div [style.background]="selectedSidebarBgColor" class="showColor"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label>Sidebar View</label>
                            <div class="d-flex">
                                <select name="sidebarView" [(ngModel)]="sidebarType" class="form-control" id="">
                                    <option value="left">Left (without collapsed)</option>
                                    <option value="leftCollapsed">Left (with collapsed)</option>
                                    <option value="top">Top</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <a href="javascript:void(0);" (click)="submit()" class="btn btn-primary mr-10" >Submit</a>
                            <a href="javascript:void(0);" (click)="reset()" class="btn btn-primary" >Reset</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>