import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class roleService {
  BASE_DOMAIN = environment.BASE_DOMAIN
  private activeModules: string[] = [];
  private activeSubmodules: string[] = [];

  constructor(private API_URL: Configuration,private http: HttpClient) { }


  fetchRoleList(): void {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.ROLE_MAPING}`;

    this.http.get<any>(url).subscribe((res: any) => {
        if (res?.responseObject?.modules) {
          const modules = res.responseObject.modules;
          modules.forEach((element: any) => {
            if (
              element.moduleName === 'Insights' ||
              element.moduleName === 'Orders' ||
              element.moduleName === 'Utilities'
              ) {
              this.activeModules.push(element.moduleName);
            }
            element.subModules.forEach((item: any) => {
              if (
                item.subModuleName === 'Orders Report' ||
                item.subModuleName === 'Active' ||
                item.subModuleName === 'Pending' ||
                item.subModuleName === 'Exception' ||
                item.subModuleName === 'Upload Orders' ||
                item.subModuleName === 'Upload Pincodes' ||
                item.subModuleName === 'Configure Carriers'
              ) {
                this.activeSubmodules.push(item.subModuleName);
              }
            });
          });
        }
      },
      (error) => {
        console.error('Failed to fetch role list', error);
      }
    );
  }

  isActiveModule(moduleName: string): boolean {
    // console.log(moduleName)
    return this.activeModules.includes(moduleName);
  }

  isActiveSubmodule(submoduleName: string): boolean {
    // console.log('Orders Report',submoduleName)
    return this.activeSubmodules.includes(submoduleName);
  }
}
