<div class="top-sidebar-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="">
        <div class="sidebar top-sidebar">
            <div class="sidebar-menu">
                <ul class="module-wrapper">
                    <li class="submenu-open" *ngFor="let item of menu" routerLinkActive="activeMenu"> 
                       <h6  class="submenu-hdr d-flex align-items-center justify-content-center">
                           <span class="ml-10 icons">
                                <div  [innerHTML]="item.icon"></div>
                                <!-- <img style="width: 16px;min-width: 16px;" [src]="item.icon" alt=""> -->
                           </span>
                           <span>
                                {{item.moduleName}}
                            </span>
                        </h6>
                       <ul class="subModules-wrapper">
                           <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngFor="let submenu of item.subModules"> -->
                               <li *ngFor="let submenu of item.items" (click)="addNewTab(submenu)">
                                <a [routerLink]="[submenu.url]" routerLinkActive="active" id="mobile_btn" (click)="toggleNavMobile()">
                                    <span [ngClass]="{'active-icon': submenu.url === router.url}" [innerHTML]="submenu.icon"></span>
                                <!-- <img class="mr-10" [src]="submenu.url === router.url ? submenu.activeIcon : submenu.icon "  alt=""> -->
                                <span>{{submenu.subModuleName}}</span></a>
                           </li>
                       </ul>
                   </li>
                   <li class="submenu-open">
                       <h6 class="submenu-hdr"><i data-feather="settings"></i> Settings</h6>
                       <ul class="subModules-wrapper">
                           <li>
                               <a (click)="logOut()" title="Logout"><i data-feather="log-out"></i><span>Logout</span> </a>
                           </li>
                           <li>
                               <a (click)="showPoDetails()" title="Change Theme"><i data-feather="layout"></i><span>Change Theme</span> </a>
                           </li>
                           
                       </ul>
                   </li>
               </ul>
            </div>
        </div>
    </div>
</div>